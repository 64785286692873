import * as Sentry from "@sentry/sveltekit";
// @ts-ignore
import {PUBLIC_SENTRY_DSN, PUBLIC_VERSION} from '$env/static/public';
import type { Handle } from "@sveltejs/kit";
import { sequence } from "@sveltejs/kit/hooks";

if (PUBLIC_SENTRY_DSN && PUBLIC_VERSION) {
    Sentry.init({
        dsn: PUBLIC_SENTRY_DSN,
        release: PUBLIC_VERSION,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        tracePropagationTargets: [
            'bills.local', 'bills.numberfortyeight.net',
        ],

        integrations: [
            new Sentry.Replay(),
            new Sentry.BrowserTracing(),
        ],

        tracesSampleRate: 1,
        profilesSampleRate: 1.0,
    });
} else {
}

export const handleError = Sentry.handleErrorWithSentry();